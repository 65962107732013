import {
  PrismicImage,
  PrismicPageDefinition,
  PrismicRichText,
  PrismicExternalLink,
} from "./types";
import { PrismicRawResourceData } from "./resources";

export type TypeAgencyPartnerPartners = {
  logo: PrismicImage;
  partner_name: string;
  partner_link: PrismicExternalLink;
  partner_country: string;
};

export type TypeAgencyPartnerPage = {
  title: string;
  meta_title: string;
  hero_text: PrismicRichText;
  hero_subtitle: PrismicRichText;

  partner_headline: string;
  partner_subheading: string;
  tech_partner_headline: string;
  tech_partner_subheading: string;

  join_partner_program_text: PrismicRichText;
  join_partner_program_image: PrismicImage;

  cta_text: string;
  cta_button_text: string;
  cta_link: PrismicPageDefinition;
  second_cta_button_text: string;
  second_cta_button_link: PrismicPageDefinition;

  partners_group: TypeAgencyPartnerPartners[];
  tech_partners_group: TypeAgencyPartnerPartners[];
  hero_image: PrismicImage;
  image: PrismicImage;
  image1: PrismicImage;

  page_meta_title: string;
  page_meta_thumbnail: PrismicImage;
  page_meta_description: string;
};

export type TypeAgencyPartnerQueryResult = {
  allPrismicAgencyPartner: {
    edges: Array<{
      node: { data: TypeAgencyPartnerPage };
    }>;
  };
};

export const getPage = (
  data: TypeAgencyPartnerQueryResult
): TypeAgencyPartnerPage => {
  return data.allPrismicAgencyPartner.edges[0].node.data;
};
