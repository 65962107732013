import { graphql, StaticQuery } from "gatsby";
import React from "react";
import "./agency-partner.scss";
import {
  getPage,
  TypeAgencyPartnerPage,
  TypeAgencyPartnerQueryResult,
} from "../../utils/queries/agency_partner";
import {
  PageHeaderCustomV2WhiteWide,
  PageHeaderStandard,
} from "../../components/page-header/page-header-temp";
import Helmet from "react-helmet";
import { PageTitle } from "../../components/title/title";
import AmazeeIoMetadata from "../../components/metadata";
import RichTextRender from "../../utils/rich-text";
import AmazeePage from "../../components/layout";
import usePreviewData from "../../utils/usePreviewData";
import { ResourceInteruption } from "../../templates/resources/customers";
import Linked from "../../utils/linkedItem";
import clsx from "classnames";

const query = graphql`
  {
    allPrismicAgencyPartner {
      edges {
        node {
          id
          data {
            title
            meta_title
            page_meta_description
            partner_headline
            partner_subheading
            tech_partner_headline
            tech_partner_subheading
            hero_text {
              html
              text
              raw
            }
            hero_subtitle {
              html
              text
              raw
            }
            join_partner_program_image {
              alt
              copyright
              url
              thumbnails
            }
            join_partner_program_text {
              html
              text
              raw
            }
            hero_image {
              alt
              copyright
              url
              thumbnails
            }
            page_meta_thumbnail {
              alt
              copyright
              url
              thumbnails
            }
            partners_group {
              partner_name
              partner_country
              logo {
                alt
                url
              }
              partner_link {
                url
                target
              }
            }
            tech_partners_group {
              partner_name
              partner_country
              logo {
                alt
                url
              }
              partner_link {
                url
                target
              }
            }
          }
        }
      }
    }
  }
`;

const AgencyPartnerPage = ({
  data,
  location,
}: {
  location: Location;
  data: TypeAgencyPartnerQueryResult;
}) => {
  const pageData = getPage(data);
  if (!pageData) return null;
  const result = usePreviewData(
    pageData,
    "PrismicAgencyPartner",
    true
  ) as TypeAgencyPartnerPage;

  const title = result.meta_title ? result.meta_title : result.title;

  return (
    <AmazeePage location={location}>
      <div id="agency-partner-page">
        <Helmet>
          <link rel="stylesheet" href="/css/carousel-single.min.css" />
          <link rel="stylesheet" href="/css/carousel-multi.min.css" />
        </Helmet>
        <PageTitle title={title} amazeeOnly={true} />
        <AmazeeIoMetadata
          title={result.title}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        <PageHeaderStandard
          component={PageHeaderCustomV2WhiteWide}
          header={RichTextRender(result.hero_text)}
          image={result.hero_image}
          subheader={RichTextRender(result.hero_subtitle)}
          withSiteName={false}
          hasSectionDivider={false}
        />

        <section id="agency-partners">
          <div id="agency-partners-anchor"></div>
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-10 text-center">
                <h2>{RichTextRender(result.partner_headline)}</h2>
              </div>
              <div className="col-12 col-lg-8 text-center mb-16">
                <p className="text-lg">
                  {RichTextRender(result.partner_subheading)}
                </p>
              </div>
            </div>

            <div className="col-12 col-lg-10 partner-wrapper">
              <div className="row flex">
                {result.partners_group.map((partner, index) => (
                  <div className="col-6 col-lg-3 partner" key={index}>
                    <div className="partner-logo flex">
                      <img src={partner.logo.url} alt={partner.logo.alt} />
                    </div>
                    <div className="partner-title">
                      <a
                        className="external"
                        href={partner.partner_link.url}
                        target="_blank"
                      >
                        {partner.partner_name}
                      </a>
                    </div>
                    <div className="partner-country">
                      {partner.partner_country}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section id="cta-bottom">
          <div className="container">
            <div className="row flex justify-center items-center">
              <div className="col-12 col-lg-3 text-left">
                <img
                  src="https://images.prismic.io/amazeeio/ebde8660-5cfc-43c4-b973-993f43026a33_Agency-Partner-Brochure.png?auto=compress%2Cformat"
                  alt="Agency Partner Program Brochure"
                />
              </div>
              <div className="col-12 col-lg-6 text-box text-left">
                <div>
                  <p>
                    Download our Agency Partner Program Brochure for a view into
                    program benefits and requirements.
                  </p>
                  <p></p>
                  <p>
                    <a href="/our-partners/program-brochure">
                      Download the Brochure
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-10"></div>
          </div>
        </section>

        <section className="a-button" id="join-our-agency-partner-program">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-6 text-left">
                <div className="description">
                  {RichTextRender(result.join_partner_program_text)}
                </div>
              </div>
              <div className="col-12 col-lg-4 text-center">
                <img
                  src={result.join_partner_program_image.url}
                  alt={result.join_partner_program_image.alt}
                />
              </div>
            </div>

            <div className="col-12 col-lg-10"></div>
          </div>
        </section>

        <section id="tech-partners">
          <div id="tech-partners-anchor"></div>
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-10 text-center">
                <h2>{RichTextRender(result.tech_partner_headline)}</h2>
              </div>
              <div className="col-12 col-lg-8 text-center">
                <p>
                  {RichTextRender(result.tech_partner_subheading)}
                </p>
              </div>
            </div>

            <div className="col-12 col-lg-10 partner-wrapper">
              <div className="row flex">
                {result.tech_partners_group.map((tech_partner, index) => (
                  <div className="col-6 col-lg-3 partner" key={index}>
                    <div className="partner-logo flex">
                      <img
                        src={tech_partner.logo.url}
                        alt={tech_partner.logo.alt}
                      />
                    </div>
                    <div className="partner-title">
                      <a
                        className={`${tech_partner.partner_link.target &&
                          "external"}`}
                        href={tech_partner.partner_link.url}
                        target={tech_partner.partner_link.target}
                      >
                        {tech_partner.partner_name}
                      </a>
                    </div>
                    <div className="partner-country">
                      {tech_partner.partner_country}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </AmazeePage>
  );
};

const StaticTypeAgencyPartnerPage: React.FC<{ location: Location }> = ({
  location,
}) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => <AgencyPartnerPage location={location} data={data} />}
    />
  );
};
export default StaticTypeAgencyPartnerPage;
